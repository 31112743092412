import { defineComponent, ref, computed, watch, getCurrentInstance, Ref, ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
// import { mapState, mapActions, mapMutations } from 'vuex'
import ModuleInfoCard from '@/components/ModuleInfoCard.vue';
import cardList from '@/const/demoList';
import { DemoListItem } from '@/types/demo';
// 建立查詢用的關鍵字
const cardListSearchable = cardList
    .map(d => {
    let metaArr = [];
    d.moduleTitle && metaArr.push(d.moduleTitle.toLowerCase());
    d.moduleName && metaArr.push(d.moduleName.toLowerCase());
    d.baseModuleTitle && metaArr.push(d.baseModuleTitle.toLowerCase());
    d.baseModuleName && metaArr.push(d.baseModuleName.toLowerCase());
    if (d.tags && d.tags.length) {
        metaArr = metaArr.concat(d.tags.map(t => t.toLowerCase()));
    }
    return {
        ...d,
        _meta: metaArr.join(',')
    };
})
    .sort((a, b) => a.moduleName.localeCompare(b.moduleName));
export default defineComponent({
    components: {
        ModuleInfoCard
    },
    setup(_, {}) {
        // @ts-ignore
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const router = useRouter();
        const pageSize = ref(12);
        const currentPage = ref(Number(route.query.page || 1));
        const keyword = computed(() => String(proxy.$store.state.keyword || ''));
        const currentKeyword = computed(() => String(route.query.keyword || ''));
        const moduleType = computed(() => String(String(proxy.$store.state.moduleType || '')));
        // const currentModuleType: ComputedRef<string> = computed(() => String(String(route.query.moduletype || '')))
        const filteredCardList = computed(() => {
            if (!currentKeyword.value && (moduleType.value === 'all')) {
                return cardListSearchable;
            }
            return cardListSearchable.filter((d) => {
                // moduleType
                if (moduleType.value && moduleType.value !== 'all' && d.baseModuleName.includes(moduleType.value) == false) {
                    return false;
                }
                // keyword
                if (d._meta.indexOf(currentKeyword.value.toLowerCase()) >= 0) {
                    return true;
                }
                return false;
            });
        });
        const currentCardList = computed(() => {
            const startIndex = pageSize.value * (currentPage.value - 1);
            const endIndex = startIndex + pageSize.value;
            return filteredCardList.value.slice(startIndex, endIndex);
        });
        watch(() => route.query, (d) => {
            // currentKeyword.value = String(d.keyword || '')
            currentPage.value = Number(d.page || 1);
            if (!d.moduletype) {
                proxy.$store.commit('mutationModuleType', 'chart'); // 回到預設值
            }
        }, { immediate: true });
        watch([keyword, moduleType], ([_keyword, _moduleType]) => {
            router.push({
                query: {
                    keyword: _keyword,
                    moduletype: _moduleType,
                    page: '1'
                }
            });
        });
        return {
            pageSize,
            filteredCardList,
            currentCardList,
            currentPage,
            currentKeyword,
            handlePageChange(d) {
                router.push({
                    query: {
                        keyword: currentKeyword.value || undefined,
                        moduletype: proxy.$store.state.moduleType,
                        page: d
                    }
                });
            }
        };
    }
});
