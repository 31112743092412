<template>
  <div class="company-card">
    <div class="company-logo">
      <router-link :to="routerLinkTo(info.moduleName)">
        <div
          class="test"
          style="position:absolute;width:100%;height:200px;z-index:100000">
        </div>
      </router-link>
      <!-- <img
        v-if="info.image"
        :src="info.image"
        :alt="info.name">
      <div
        v-else
        style="font-size:42px;text-align:center;line-height:64px;color:#c0c4cc"
        alt="無預覽圖片">
        無預覽圖片
      </div> -->
      <!-- <iframe
        width="200%"
        height="200%"
        sandbox="allow-scripts"
        loading="lazy"
        scrolling="no"
        style="transform: scale(0.5); transform-origin: top left"
        :src="`test/${info.moduleName}`"
      /> -->
      <!-- scale為0.5，所以裡面的長寬都要2倍 -->
      <div
        style="transform: scale(0.5,0.5); transform-origin: top left;width:200%;height:200%">
        <DynamicModuleContainer
          :width="'100%'"
          :height="'100%'"
          :data="info.previewData"
        />
      </div>
    </div>
    <div class="company-function">
      <ul>
        <li>
          <!-- <el-tooltip class="item" effect="dark" content="收藏" placement="left">
            <button
              class="share-btn"
              :class="{active:isCollection}"
              @click="toggleCollection">
              <font-awesome-icon :icon="['fas', 'bookmark']"/>
            </button>
          </el-tooltip> -->
          <el-tooltip class="item" effect="dark" content="整頁" placement="left">
            <router-link :to="routerTestLinkTo(info.moduleName)" target="_blank">
              <button class="share-btn">
                <font-awesome-icon :icon="['fas', 'share']"/>
                <!-- <fa icon="bookmark" type="fas" class="share-btn-icon"></fa> -->
              </button>
            </router-link>
          </el-tooltip>
        </li>
        <!-- <li>
          <el-tooltip class="item" effect="dark" content="分享" placement="left">
            <button class="share-btn">
              <font-awesome-icon :icon="['fas', 'share']"/>
            </button>
          </el-tooltip>
        </li> -->
      </ul>
    </div>
    <div class="company-container">
      <div class="company-title">
        <router-link
          style="color:#ffffff"
          :to="routerLinkTo(info.moduleName)">
            {{info.moduleTitle}}
          </router-link>
      </div>
      <div class="company-subtitle">
        {{info.moduleName}}
        <!-- <span :class="companyOperatingClass">
          {{company.company_operate}}
        </span> -->
      </div>
      <div class="company-content">
        <ul class="company-info-list">
          <li class="company-info-item">
            <div class="company-info-item__label">類別：</div>
            <div class="company-info-item__value">{{info.baseModuleName}} ({{info.baseModuleTitle}})</div>
          </li>
          <li v-if="info.description" class="company-info-item">
            <div class="company-info-item__label">說明：</div>
            <div class="company-info-item__value">{{info.description}}</div>
          </li>
        </ul>
        <Tags
          :uniID="info.uniID"
          :tags="info.tags">
        </Tags>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import { collectionsDelete, collectionAdd } from '@/apis/index.ts'
import { formatEmpty, formatNumberWithComma } from '@/utils/utils.js'
import Tags from '@/components/Tags.vue'
import DynamicModuleContainer from '@/components/DynamicModuleContainer.vue'

export default {
  props: {
    info: {
      required: true
    },
    // routerLink: {
    //   default () {
    //     return { name: 'index' }
    //   }
    // }
  },
  components: {
    Tags,
    DynamicModuleContainer
  },
  data () {
    return {
      // tagInputVisible: true,
      // tagInputValue: '',
      // customTags: [],
    }
  },
  computed: {
    // ...mapState([
    //   'userInfo'
    // ]),
    // ...mapState([
    //   'collectionsKeyword'
    // ]),
    isCollection: {
      get () {
        return false
        // return this.collectionsKeyword.includes(this.company.uniID)
      },
      async set (d) {
        // if (d === true) {
        //   // this.mutationCollectionsAdd(this.company.uniID)
        //   await collectionAdd({
        //     email: this.userInfo.email,
        //     company_uniID: this.company.uniID,
        //     company_name: this.company.company_name,
        //     note: ''
        //   })
        // } else {
        //   // this.mutationCollectionsRemove(this.company.uniID)
        //   await collectionsDelete({
        //     email: this.userInfo.email,
        //     companys: [this.company.uniID]
        //   })
        // }
        // this.actionCollections()
      }
    },
    // companyPhone () {
    //   if (this.company.multi && this.company.multi.phone && this.company.multi.phone.length) {
    //     return this.company.multi.phone[0].value || '-'
    //   } else {
    //     return '-'
    //   }
    // },
    // companyEmail () {
    //   if (this.company.multi && this.company.multi.email && this.company.multi.email.length) {
    //     return this.company.multi.email[0].value || '-'
    //   } else {
    //     return '-'
    //   }
    // },
    // avatarCoverText () {
    //   let text = ''
    //   if (this.company.company_name) {
    //     text = this.company.company_name.slice(0, 1)
    //   }
    //   return text
    // },
    // isCompanyOperating () {
    //   if (this.company.company_operate === '營業中') {
    //     return true
    //   }
    //   return false
    // },
    // userLabels () {
    //   return this.company['user_label'] || []
    // },
    // companyOperatingClass () {
    //   if (this.company.company_operate === '營業中') {
    //     return {
    //       'text-color-success': true
    //     }
    //   }
    //   return {
    //     'text-color-danger': true
    //   }
    // }
  },
  methods: {
    // ...mapActions([
    //   'actionCollections',
    // ]),
    formatNumberWithComma,
    formatEmpty,
    toggleCollection () {
      this.isCollection = !this.isCollection
      // // if (this.currentIsCollections[company.uniID] === true) {
      // //   apis.collectionAdd({
      // //     email: this.userInfo.email,
      // //     company_uniID: company.uniID,
      // //     company_name: company.company_name,
      // //     note: ''
      // //   })
      // // } else {
      // //   apis.collectionsDelete({
      // //     email: this.userInfo.email,
      // //     companys: [company.uniID]
      // //   })
      // // }
    },
    routerLinkTo (moduleName) {
      return {
        name: 'demo-modules-modulename',
        params: {
          moduleName
        }
      }
    },
    routerTestLinkTo (moduleName) {
      return {
        name: 'fullscreen-modulename',
        params: {
          moduleName
        }
      }
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/base/_core';

.company-card {
  max-height: 100% !important;
  background-color: $color-secondary-black;
  color: $color-pure-white;
}

.company-card .company-logo {
  width: 100% !important;
  height: 200px !important;
  box-shadow: 6px 6px 6px 2px rgba(0, 0, 0, 0.2);
}

.company-card .company-container {
  margin-top: 0rem !important;
}

.company-card .company-function {
  top: 15rem !important;
}
</style>